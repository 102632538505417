import { useEffect } from "react";
import { useLocation, useNavigationType } from "react-router-dom";

const useScrollRestoration = () => {
  const { pathname } = useLocation();
  const navigationType = useNavigationType();
  console.log("type", navigationType);
  useEffect(() => {
    if (navigationType === "PUSH") {
      // No smooth scrolling when navigating to a new page
      document.documentElement.style.scrollBehavior = "auto";
      window.scrollTo(0, 0);
    } else {
      // Smooth scrolling for back/forward/reload (POP or REPLACE)
      document.documentElement.style.scrollBehavior = "smooth";
      window.scrollTo(0, 0);
    }
  }, [pathname, navigationType]);
};

export default useScrollRestoration;
