import React, { useState } from "react";
import styles from "./Leadership.module.css";
import { Col, Modal, Row, Tab, Tabs } from "react-bootstrap";
import FadeInSection from "../FadeInSection";

import { ReactComponent as LineLeft } from "../../assets/images/LineLeft.svg";
import { ReactComponent as LineRight } from "../../assets/images/LineRight.svg";
import { ReactComponent as Tree } from "../../assets/images/Tree.svg";
import { ReactComponent as LineLeftMobile } from "../../assets/images/LineLeftMobile.svg";
import { ReactComponent as LineRightMobile } from "../../assets/images/LineRightMobile.svg";
import { ReactComponent as TreeMobile } from "../../assets/images/TreeMobile.svg";
import SubBanner from "../SubBanner";

const Leadership = () => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <SubBanner />
      <div className="content-wrapper">
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalShow}
          onHide={() => setModalShow(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={styles["modal-body"]}>
              <div className="container">
                <div className="row">
                  <div className="col-md-6 col-12 mb-3">
                    <img
                      src="./placeholder.jpg"
                      className="img-fluid"
                      alt="placeholder"
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <h5>NAME</h5>
                    <h6>Job Position</h6>
                    <p style={{ marginTop: "2rem" }}>
                      Lorem ipsum dolor sit amet consectetur. Elementum ac
                      habitant eget sed lobortis feugiat leo consectetur
                      adipiscing. In eget arcu fringilla varius nulla sodales.
                      Vel libero tortor nunc lorem pharetra lorem egestas. Arcu
                      gravida ultrices vitae adipiscing feugiat suspendisse sed
                      nisl viverra. Viverra ut dui nisi volutpat sit facilisis
                      cursus volutpat. Nisl nulla phasellus nullam sodales
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <div className={styles["leadership"]}>
          <div className={styles["leadership-title"]}>Our Leadership</div>
          <div className={styles["leadership-content"]}>
            <Tabs
              defaultActiveKey="home"
              transition={false}
              className="mb-3"
              style={{
                border: 0,
                width: "100%",
                gap: "1.5rem",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Tab
                eventKey="home"
                title="Board of Directors"
                className={styles["tab-content"]}
                style={{ border: 0, width: "100%" }}
              >
                <Row
                  style={{ marginBottom: "1.5rem", paddingTop: "3.5rem" }}
                  className={styles["leadership-row"]}
                >
                  <Col className={styles["card-col"]}>
                    <div className={styles.card}>
                      <div className={styles.overlay}>
                        <div className={styles.surname}>Name</div>
                        <div className={styles.jobposition}>COO</div>
                      </div>
                    </div>
                  </Col>
                  <Col className={styles["card-col"]}>
                    <div className={styles.card}>
                      <div className={styles.overlay}>
                        <div className={styles.surname}>Name</div>
                        <div className={styles.jobposition}>COO</div>
                      </div>
                    </div>
                  </Col>
                  <Col className={styles["card-col"]}>
                    <div className={styles.card}>
                      <div className={styles.overlay}>
                        <div className={styles.surname}>Name</div>
                        <div className={styles.jobposition}>COO</div>
                      </div>
                    </div>
                  </Col>
                  <Col className={styles["card-col"]}>
                    <div className={styles.card}>
                      <div className={styles.overlay}>
                        <div className={styles.surname}>Name</div>
                        <div className={styles.jobposition}>COO</div>
                      </div>
                    </div>
                  </Col>
                  <Col className={styles["card-col"]}>
                    <div className={styles.card}>
                      <div className={styles.overlay}>
                        <div className={styles.surname}>Name</div>
                        <div className={styles.jobposition}>COO</div>
                      </div>
                    </div>
                  </Col>
                  <Col className={styles["card-col"]}>
                    <div className={styles.card}>
                      <div className={styles.overlay}>
                        <div className={styles.surname}>Name</div>
                        <div className={styles.jobposition}>COO</div>
                      </div>
                    </div>
                  </Col>
                  <Col className={styles["card-col"]}>
                    <div className={styles.card}>
                      <div className={styles.overlay}>
                        <div className={styles.surname}>Name</div>
                        <div className={styles.jobposition}>COO</div>
                      </div>
                    </div>
                  </Col>
                  <Col className={styles["card-col"]}>
                    <div className={styles.card}>
                      <div className={styles.overlay}>
                        <div className={styles.surname}>Name</div>
                        <div className={styles.jobposition}>COO</div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Tab>
              <Tab
                eventKey="profile"
                title="Management Team"
                style={{ border: 0, width: "100%", paddingTop: "3.5rem" }}
              >
                <Row style={{ marginBottom: "1.5rem" }}>
                  <Col className={styles["card-col"]}>
                    <div className={styles.card}>
                      <div className={styles.overlay}>
                        <div className={styles.surname}>Name</div>
                        <div className={styles.jobposition}>COO</div>
                      </div>
                    </div>
                  </Col>
                  <Col className={styles["card-col"]}>
                    <div className={styles.card}>
                      <div className={styles.overlay}>
                        <div className={styles.surname}>Name</div>
                        <div className={styles.jobposition}>COO</div>
                      </div>
                    </div>
                  </Col>
                  <Col className={styles["card-col"]}>
                    <div className={styles.card}>
                      <div className={styles.overlay}>
                        <div className={styles.surname}>Name</div>
                        <div className={styles.jobposition}>COO</div>
                      </div>
                    </div>
                  </Col>
                  <Col className={styles["card-col"]}>
                    <div className={styles.card}>
                      <div className={styles.overlay}>
                        <div className={styles.surname}>Name</div>
                        <div className={styles.jobposition}>COO</div>
                      </div>
                    </div>
                  </Col>

                  <Col className={styles["card-col"]}>
                    <div className={styles.card}>
                      <div className={styles.overlay}>
                        <div className={styles.surname}>Name</div>
                        <div className={styles.jobposition}>COO</div>
                      </div>
                    </div>
                  </Col>
                  <Col className={styles["card-col"]}>
                    <div className={styles.card}>
                      <div className={styles.overlay}>
                        <div className={styles.surname}>Name</div>
                        <div className={styles.jobposition}>COO</div>
                      </div>
                    </div>
                  </Col>
                  <Col className={styles["card-col"]}>
                    <div className={styles.card}>
                      <div className={styles.overlay}>
                        <div className={styles.surname}>Name</div>
                        <div className={styles.jobposition}>COO</div>
                      </div>
                    </div>
                  </Col>
                  <Col className={styles["card-col"]}>
                    <div className={styles.card}>
                      <div className={styles.overlay}>
                        <div className={styles.surname}>Name</div>
                        <div className={styles.jobposition}>COO</div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Tab>
            </Tabs>
          </div>
        </div>{" "}
        <div className={styles["organization-structure"]}>
          <FadeInSection>
            <div className={styles["organization-title"]}>
              Organization Structure
            </div>
          </FadeInSection>
          <FadeInSection delay="0.2s">
            <div className={styles["organization-description"]}>
              Lorem ipsum dolor sit amet consectetur. Arcu laoreet felis
              pharetra tortor duis. Nulla mattis tellus pellentesque consectetur
              magna in et vulputate egestas. Tortor ut erat viverra pretium
              tristique non molestie neque.
            </div>
          </FadeInSection>
        </div>
        <div className={styles["organization-wrapper"]}>
          <div className={styles["organization-content"]}>
            <FadeInSection delay="0.3s">
              <div className={styles["team-member-row1"]}>
                <div
                  className={styles["team-member1"]}
                  onClick={() => setModalShow(true)}
                >
                  <div className={styles["team-member-image1"]}></div>
                  <div className={styles["team-member-info1"]}>
                    <div className={styles["team-member-name1"]}>
                      NAME SURNAME
                    </div>
                    <span className={styles["team-member-position1"]}>
                      Job Position
                    </span>
                  </div>
                  <LineLeft className={styles["line-left"]} />
                  <LineRight className={styles["line-right"]} />
                  <LineLeftMobile className={styles["line-left-mobile"]} />
                  <LineRightMobile className={styles["line-right-mobile"]} />
                </div>
              </div>
            </FadeInSection>
            <FadeInSection delay="0.4">
              <div
                className={styles["team-member-row2"]}
                style={{ paddingInline: "0.3rem" }}
              >
                <div className={styles["team-member-row2-col1"]}>
                  <div
                    className={styles["team-member2"]}
                    onClick={() => setModalShow(true)}
                  >
                    <div className={styles["team-member-image2"]}></div>
                    <div className={styles["team-member-info2"]}>
                      <div className={styles["team-member-name2"]}>
                        NAME SURNAME
                      </div>
                      <span className={styles["team-member-position2"]}>
                        Job Position
                      </span>
                    </div>
                  </div>
                </div>
                <div className={styles["team-member-row2-col2"]}>
                  <div
                    className={styles["team-member2"]}
                    onClick={() => setModalShow(true)}
                  >
                    <div className={styles["team-member-image2"]}></div>
                    <div className={styles["team-member-info2"]}>
                      <div className={styles["team-member-name2"]}>
                        NAME SURNAME
                      </div>
                      <span className={styles["team-member-position2"]}>
                        Job Position
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </FadeInSection>
            <FadeInSection delay="0.5">
              <div className={styles["team-member-row3"]}>
                <div className={styles["team-member-row3-col1"]}>
                  <Tree className={styles["tree-left"]} />
                  <TreeMobile className={styles["tree-mobile"]} />
                  <div
                    className={styles["team-member3"]}
                    onClick={() => setModalShow(true)}
                  >
                    <div className={styles["team-member-image3"]}></div>
                    <div className={styles["team-member-info3"]}>
                      <div className={styles["team-member-name3"]}>
                        NAME SURNAME
                      </div>
                      <span className={styles["team-member-position3"]}>
                        Job Position
                      </span>
                    </div>
                  </div>
                  <div
                    className={styles["team-member3"]}
                    onClick={() => setModalShow(true)}
                  >
                    <div className={styles["team-member-image3"]}></div>
                    <div className={styles["team-member-info3"]}>
                      <div className={styles["team-member-name3"]}>
                        NAME SURNAME
                      </div>
                      <span className={styles["team-member-position3"]}>
                        Job Position
                      </span>
                    </div>
                  </div>
                  <div
                    className={styles["team-member3"]}
                    onClick={() => setModalShow(true)}
                  >
                    <div className={styles["team-member-image3"]}></div>
                    <div className={styles["team-member-info3"]}>
                      <div className={styles["team-member-name3"]}>
                        NAME SURNAME
                      </div>
                      <span className={styles["team-member-position3"]}>
                        Job Position
                      </span>
                    </div>
                  </div>
                </div>
                <div className={styles["team-member-row3-col2"]}>
                  <Tree className={styles["tree-right"]} />
                  <div
                    className={styles["team-member3"]}
                    onClick={() => setModalShow(true)}
                  >
                    <div className={styles["team-member-image3"]}></div>
                    <div className={styles["team-member-info3"]}>
                      <div className={styles["team-member-name3"]}>
                        NAME SURNAME
                      </div>
                      <span className={styles["team-member-position3"]}>
                        Job Position
                      </span>
                    </div>
                  </div>
                  <div
                    className={styles["team-member3"]}
                    onClick={() => setModalShow(true)}
                  >
                    <div className={styles["team-member-image3"]}></div>
                    <div className={styles["team-member-info3"]}>
                      <div className={styles["team-member-name3"]}>
                        NAME SURNAME
                      </div>
                      <span className={styles["team-member-position3"]}>
                        Job Position
                      </span>
                    </div>
                  </div>
                  <div
                    className={styles["team-member3"]}
                    onClick={() => setModalShow(true)}
                  >
                    <div className={styles["team-member-image3"]}></div>
                    <div className={styles["team-member-info3"]}>
                      <div className={styles["team-member-name3"]}>
                        NAME SURNAME
                      </div>
                      <span className={styles["team-member-position3"]}>
                        Job Position
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </FadeInSection>
          </div>
        </div>
      </div>
    </>
  );
};

export default Leadership;
