// Loader.js
import React from "react";
import styles from "./Loader.module.css"; // Import the CSS Module

const Loader = () => (
  <div className={styles.body}>
    <div className={styles.container}>
      <div className={styles.cube}>
        <div className={styles.cube__inner}></div>
      </div>
      <div className={styles.cube}>
        <div className={styles.cube__inner}></div>
      </div>
      <div className={styles.cube}>
        <div className={styles.cube__inner}></div>
      </div>
    </div>
  </div>
);

export default Loader;
