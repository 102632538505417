import React, { useState, useRef, useEffect } from "react";
import styles from "./Tabs.module.css";

const Tabs = () => {
  const [activeTab, setActiveTab] = useState("mission");
  const [indicatorStyle, setIndicatorStyle] = useState({});
  const tabRefs = useRef([]);

  useEffect(() => {
    // Update the background position and width dynamically based on the active tab
    const activeIndex =
      activeTab === "mission" ? 0 : activeTab === "vision" ? 1 : 2;
    const tab = tabRefs.current[activeIndex];
    if (tab) {
      setIndicatorStyle({
        width: `${tab.offsetWidth}px`,
        transform: `translateX(${tab.offsetLeft}px)`,
      });
    }
  }, [activeTab]);

  const renderContent = () => {
    switch (activeTab) {
      case "mission":
        return (
          <>
            <div className={styles.title}>Our Mission</div>
            <div className={styles.text}>
              To integrate digital distribution, logistics, and retail,
              empowering Southeast Asian businesses and consumers.
            </div>
          </>
        );
      case "vision":
        return (
          <>
            <div className={styles.title}>Our Vision</div>
            <div className={styles.text}>
              To create a seamless, borderless marketplace that drives Southeast
              Asian commerce and connectivity.
            </div>
          </>
        );
      case "values":
        return (
          <>
            <div className={styles.title}>Core Values</div>
            <div className={styles.text}>
              <div className={styles.listTitle}>1. Integration</div>
              <div className={styles.listText}>
                We believe in seamlessly connecting digital and physical realms
                to create comprehensive solutions.
              </div>
              <div className={styles.listTitle}>2. Empowerment</div>
              <div className={styles.listText}>
                We are committed to enabling SMEs and local businesses to reach
                broader markets and achieve growth.
              </div>
              <div className={styles.listTitle}>3. Innovation</div>
              <div className={styles.listText}>
                We constantly seek new ways to improve our services and adapt to
                evolving market needs.
              </div>
              <div className={styles.listTitle}>4. Regional Connectivity</div>
              <div className={styles.listText}>
                We strive to bridge gaps between Southeast Asian countries,
                fostering cross-border commerce and cultural exchange.
              </div>
              <div className={styles.listTitle}>5. Customer-Centricity</div>
              <div className={styles.listText}>
                We prioritize the needs of both businesses and consumers in
                everything we do.
              </div>
              <div className={styles.listTitle}>6. Efficiency</div>
              <div className={styles.listText}>
                We aim to optimize logistics and operations to provide swift,
                reliable services.
              </div>
              <div className={styles.listTitle}>7. Adaptability</div>
              <div className={styles.listText}>
                We embrace change and are ready to expand into new markets and
                services.
              </div>
              <div className={styles.listTitle}>8. Collaboration</div>
              <div className={styles.listText}>
                We believe in the power of partnerships and working together
                with local communities and businesses.
              </div>
              <div className={styles.listTitle}>9. Sustainability</div>
              <div className={styles.listText}>
                We are committed to responsible growth that benefits all
                stakeholders in the long term.
              </div>
              <div className={styles.listTitle}>10. Inclusivity</div>
              <div className={styles.listText}>
                We aim to create opportunities for businesses of all sizes and
                serve diverse consumer needs across the region.
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.tabsContainer}>
      <div className={styles.tabs}>
        <div className={styles.indicator} style={indicatorStyle} />
        <button
          ref={(el) => (tabRefs.current[0] = el)}
          className={`${styles.tabButton} ${
            activeTab === "mission" ? styles.active : ""
          }`}
          onClick={() => setActiveTab("mission")}
        >
          Our Mission
        </button>
        <button
          ref={(el) => (tabRefs.current[1] = el)}
          className={`${styles.tabButton} ${
            activeTab === "vision" ? styles.active : ""
          }`}
          onClick={() => setActiveTab("vision")}
        >
          Our Vision
        </button>
        <button
          ref={(el) => (tabRefs.current[2] = el)}
          className={`${styles.tabButton} ${
            activeTab === "values" ? styles.active : ""
          }`}
          onClick={() => setActiveTab("values")}
        >
          Core Values
        </button>
      </div>
      <div className={styles.tabContent}>{renderContent()}</div>
    </div>
  );
};

export default Tabs;
