import React, { useState, useRef, useEffect } from "react";
import styles from "./FadeInSection.module.css";

const FadeInSection = ({ children, delay = "0s" }) => {
  const [hasPlayed, setHasPlayed] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const domRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setVisible(true);
            if (!hasPlayed) {
              setHasPlayed(true);
            }
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    const currentRef = domRef.current;
    observer.observe(currentRef);

    return () => {
      observer.unobserve(currentRef);
    };
  }, [hasPlayed]);

  return (
    <div
      className={`${styles.fadeInSection} ${
        isVisible && hasPlayed ? styles.isVisible : ""
      }`}
      style={{ "--fade-delay": delay }}
      ref={domRef}
    >
      {children}
    </div>
  );
};

export default FadeInSection;
