import React from "react";
import SubBannerImg from "../assets/images/SubBanner.svg";
import styles from "./SubBanner.module.css";
const SubBanner = () => {
  return (
    <div className={styles.container}>
      <img src={SubBannerImg} alt="sub-banner" className={styles.image}></img>
    </div>
  );
};

export default SubBanner;
