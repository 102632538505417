import image1 from "./Image1.png";
import image2 from "./Image2.png";
import image3 from "./Image3.png";
import image4 from "./Image4.png";
import image5 from "./Image5.png";
import image6 from "./Image6.png";
import image7 from "./Image7.png";
import image8 from "./Image8.png";

const images = {
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
};

export default images;
