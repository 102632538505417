import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import styles from "./Footer.module.css";
import { ReactComponent as MapPinOutlinedWhite } from "../../assets/icons/MapPinOutlinedWhite.svg";
import { ReactComponent as PhoneFilledWhite } from "../../assets/icons/PhoneFilledWhite.svg";
import { ReactComponent as EmailFilledWhite } from "../../assets/icons/EmailFilledWhite.svg";
import { ReactComponent as FacebookBlue } from "../../assets/icons/FacebookBlue.svg";
import { ReactComponent as MessengerBlue } from "../../assets/icons/MessengerBlue.svg";
import { ReactComponent as WhatsappBlue } from "../../assets/icons/WhatsappBlue.svg";
import { ReactComponent as TikTokBlue } from "../../assets/icons/TikTokBlue.svg";
import { ReactComponent as ArrowUp } from "../../assets/icons/ArrowUp.svg";

const Footer = () => {
  const [showScrollTop, setShowScrollTop] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 50 * (window.innerHeight / 100); // 300vh in pixels
      if (window.scrollY > scrollThreshold) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Scroll smoothly to the top when clicked
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Container fluid className={styles.container} style={{ padding: 0 }}>
      {/* Your footer content */}
      <Row className={styles.footer}>
        <Col xs={12} sm={6} md={3} className={styles.section}>
          <img
            alt="sentosa-link"
            src="/LogoWhite.svg"
            className={styles.logo}
          />
        </Col>
        <Col xs={12} sm={6} md={2} className={styles.section}>
          <h3 className={styles.title}>Company</h3>
          <NavLink className={styles.text} to="/">
            Home
          </NavLink>
          <NavLink className={styles.text} to="/about_us">
            About Us
          </NavLink>
          <NavLink className={styles.text} to="our_technology">
            Our Technology
          </NavLink>
          <NavLink className={styles.text} to="contact_us">
            Contact Us
          </NavLink>
        </Col>
        <Col xs={12} sm={6} md={6} className={styles.section}>
          <div style={{ marginBottom: "3.12rem" }}>
            <div style={{ marginBottom: "1.25rem" }}>
              <span
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                  marginRight: "0.94rem",
                }}
              >
                <MapPinOutlinedWhite />
              </span>
              <span className={styles.text}>
                111, North Bridge Road, 02-02B Peninsula Plaza, 179098
              </span>
            </div>
            <Row style={{ margin: 0 }}>
              <Col xs={12} sm={6} md={5} style={{ margin: 0, padding: 0 }}>
                <div style={{ marginBottom: "1.25rem" }}>
                  <span
                    style={{
                      width: "1.5rem",
                      height: "1.5rem",
                      marginRight: "0.94rem",
                    }}
                  >
                    <PhoneFilledWhite />
                  </span>
                  <span className={styles.text}>+65 9881 2787</span>
                </div>
              </Col>
              <Col xs={12} sm={6} md={7} style={{ margin: 0, padding: 0 }}>
                <div>
                  <span
                    style={{
                      width: "1.5rem",
                      height: "1.5rem",
                      marginRight: "0.94rem",
                    }}
                  >
                    <EmailFilledWhite />
                  </span>
                  <span className={styles.text}>hello@sentosalink.com</span>
                </div>
              </Col>
            </Row>
          </div>
          <Row style={{ margin: 0 }}>
            <Col xs={12} sm={6} md={3} style={{ margin: 0, padding: 0 }}>
              <div
                className={styles.text}
                style={{ opacity: "60%", marginBottom: "1rem" }}
              >
                Follow Us
              </div>
            </Col>
            <Col xs={12} sm={6} md={6} style={{ margin: 0, padding: 0 }}>
              <div
                style={{
                  display: "flex",
                  gap: "1.25rem",
                  paddingInline: "1.25rem",
                }}
              >
                <a
                  href="https://www.facebook.com/share/nfUDuVo11cYyJZdx/?mibextid=LQQJ4d"
                  style={{ opacity: "100%" }}
                >
                  <FacebookBlue />
                </a>
                <a href="http://m.me/sentosalink" style={{ opacity: "100%" }}>
                  <MessengerBlue />
                </a>
                <a href="https://wa.me/6598812787" style={{ opacity: "100%" }}>
                  <WhatsappBlue />
                </a>
                <a href="#" style={{ opacity: "100%" }}>
                  <TikTokBlue />
                </a>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className={styles.copyright}>
        <Col xs={12} style={{ margin: 0 }}>
          <span className={styles["copyright-text"]}>
            Copyright© 2024 Sentosa link. All rights reserved.
          </span>
        </Col>
      </Row>

      {/* Scroll to top button */}

      <div
        className={`${styles.scrollToTop} ${
          showScrollTop ? styles.visible : ""
        }`}
        onClick={scrollToTop}
      >
        <ArrowUp />
      </div>
    </Container>
  );
};

export default Footer;
