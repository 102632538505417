import React from "react";
import { Outlet } from "react-router-dom";
import styles from "./Layout.module.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useScrollRestoration } from "../../hooks";

const Layout = ({ children }) => {
  useScrollRestoration();
  return (
    <div className={styles.container}>
      <Header />
      <div style={{ marginTop: "109.19px" }}>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
