import React from "react";
import SubBanner from "../SubBanner";
import styles from "./OurTechnology.module.css";
import MultiCarousel from "react-multi-carousel";
import operation1 from "../../assets/images/operation1.svg";
import operation2 from "../../assets/images/operation2.svg";
import operation3 from "../../assets/images/operation3.svg";
import operation4 from "../../assets/images/operation4.svg";
import { ReactComponent as ArrowLeft } from "../../assets/icons/ArrowLeft.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/ArrowRight.svg";
import dms1 from "../../assets/images/dms1.svg";
import dms2 from "../../assets/images/dms2.svg";
import dms3 from "../../assets/images/dms3.svg";
import dms4 from "../../assets/images/dms4.png";
import dms5 from "../../assets/images/dms5.svg";
import dms6 from "../../assets/images/dms6.svg";
import dms7 from "../../assets/images/dms7.svg";
import dms8 from "../../assets/images/dms8.svg";
import dms9 from "../../assets/images/dms9.svg";
import dms10 from "../../assets/images/dms10.svg";
import dms11 from "../../assets/images/dms11.svg";
import FadeInSection from "../FadeInSection";

const responsive = {
  extraLargeDesktop: {
    breakpoint: { max: 4000, min: 1600 },
    items: 3,
  },
  superLargeDesktop: {
    breakpoint: { max: 1600, min: 1200 },
    items: 3,
  },
  largeDesktop: {
    breakpoint: { max: 1200, min: 1024 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const OurTechnology = () => {
  const CustomLeftArrow = ({ onClick }) => (
    <div className={styles.arrowLeft} onClick={onClick}>
      <ArrowLeft />
    </div>
  );

  const CustomRightArrow = ({ onClick }) => (
    <div className={styles.arrowRight} onClick={onClick}>
      <ArrowRight />
    </div>
  );

  const CustomLeftArrowDms = ({ onClick }) => (
    <div className={styles.arrowLeft} onClick={onClick} style={{ top: "24%" }}>
      <ArrowLeft />
    </div>
  );

  const CustomRightArrowDms = ({ onClick }) => (
    <div className={styles.arrowRight} onClick={onClick} style={{ top: "24%" }}>
      <ArrowRight />
    </div>
  );

  return (
    <>
      <SubBanner />

      <div className="content-wrapper">
        <div className={styles.container}>
          <FadeInSection>
            <div className={styles.title}>Streamlining Our Operations</div>
          </FadeInSection>
          <FadeInSection delay="0.1s">
            <div className={styles.subtitle}>
              Custom In-House ERP Solution for Integrated Finance, Procurement,
              <br />
              Multi-Location Inventory, and Customer Management
            </div>
          </FadeInSection>

          <div className={styles.content}>
            <FadeInSection delay="0.2s">
              <MultiCarousel
                className="operation"
                responsive={responsive}
                swipeable={true}
                draggable={true}
                showDots={false}
                arrows={true}
                ssr={true} // means to render carousel on server-side.
                infinite={false}
                keyBoardControl={true}
                containerClass="carousel-container"
                // partialVisible={true}
                customTransition="transform 500ms ease-in-out"
                //centerMode={true}
                autoPlay={true}
                autoPlaySpeed={10000}
                customLeftArrow={<CustomLeftArrow />}
                customRightArrow={<CustomRightArrow />}
                slidesToSlide={1}
                rewind={true}
                rewindWithAnimation={true}
                // removeArrowOnDeviceType={["tablet", "mobile"]}
                // deviceType={this.props.deviceType}
                dotListClass="custom-dot-list-style"
                focusOnSelect={false}

                // itemClass="carousel-item-padding-40-px"
              >
                <div className={styles.card}>
                  <div style={{ maxHeight: "15.0625rem" }}>
                    <img src={operation1} alt="operation1" />
                  </div>
                  <div>
                    <div className={styles.title}>Accounting & Finance</div>
                    <div className={styles.text}>
                      <ul>
                        <li>General ledger management</li>
                        <li>Accounts payable and receivable</li>
                        <li>Financial reporting and analysis</li>
                        <li>Budgeting and forecasting</li>
                        <li>Tax management</li>
                        <li>Multi-currency support</li>
                        <li>Asset management</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className={styles.card}>
                  <div>
                    <img src={operation2} alt="operation1" />
                  </div>
                  <div>
                    <div className={styles.title}>Orders & Procurement</div>
                    <div className={styles.text}>
                      <ul>
                        <li>Purchase order creation and management</li>
                        <li>Vendor management</li>
                        <li>Requisition processing</li>
                        <li>Contract management</li>
                        <li>Spend analysis</li>
                        <li>Supplier performance tracking</li>
                        <li>Integration with inventory module</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className={styles.card}>
                  <div>
                    <img src={operation3} alt="operation1" />
                  </div>
                  <div>
                    <div className={styles.title}>Inventory & Warehouse</div>
                    <div className={styles.text}>
                      <ul>
                        <li>Multi-branch and multi-warehouse support</li>
                        <li>Real-time inventory tracking</li>
                        <li>Barcode and RFID integration</li>
                        <li>Batch and serial number tracking</li>
                        <li>Automated reorder points</li>
                        <li>Inventory valuation</li>
                        <li>Pick, pack, and ship management</li>
                        <li>Integration with orders module</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className={styles.card}>
                  <div>
                    <img src={operation4} alt="operation1" />
                  </div>
                  <div>
                    <div className={styles.title}>
                      Customer Relationship Management (CRM)
                    </div>
                    <div className={styles.text}>
                      <ul>
                        <li>Contact and lead management</li>
                        <li>Sales pipeline tracking</li>
                        <li>Customer communication history</li>
                        <li>Task and activity management</li>
                        <li>Email marketing integration</li>
                        <li>Customer support ticket system</li>
                        <li>Analytics and reporting</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </MultiCarousel>
            </FadeInSection>
          </div>
        </div>
        <div className={styles.container}>
          <FadeInSection>
            <div className={styles.title} style={{ marginBottom: "5rem" }}>
              Delivery Management System
            </div>
          </FadeInSection>

          <div className={styles.content}>
            <FadeInSection delay="0.2s">
              <MultiCarousel
                className="operation"
                responsive={responsive}
                swipeable={true}
                draggable={true}
                showDots={false}
                arrows={true}
                ssr={true} // means to render carousel on server-side.
                infinite={false}
                keyBoardControl={true}
                autoPlay={true}
                autoPlaySpeed={5000}
                containerClass="carousel-container"
                // partialVisible={true}
                customTransition="transform 500ms ease-in-out"
                //centerMode={true}
                customLeftArrow={<CustomLeftArrowDms />}
                customRightArrow={<CustomRightArrowDms />}
                slidesToSlide={1}
                rewind={true}
                rewindWithAnimation={true}
                // removeArrowOnDeviceType={["tablet", "mobile"]}
                // deviceType={this.props.deviceType}
                dotListClass="custom-dot-list-style"
                focusOnSelect={false}
                // itemClass="carousel-item-padding-40-px"
              >
                <div className={styles["dms-card"]}>
                  <div className={styles["img-container"]}>
                    <img src={dms1} alt="dms1" />
                  </div>
                  <div>
                    <div className={styles.title}>MERCHANT DASHBOARD</div>
                    <div className={styles.text}>
                      <ul>
                        <li>Create Shipments</li>
                        <li>Request Pickup</li>
                        <li>Track Shipments</li>
                        <li>Payment Transactions</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className={styles["dms-card"]}>
                  <div className={styles["img-container"]}>
                    <img src={dms2} alt="dms2" />
                  </div>
                  <div>
                    <div className={styles.title}>MERCHANT APP</div>
                    <div className={styles.text}>
                      <ul>
                        <li>Create Shipments</li>
                        <li>Request Pickup</li>
                        <li>Track Shipments</li>
                        <li>Payment Transactions</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className={styles["dms-card"]}>
                  <div className={styles["img-container"]}>
                    <img src={dms3} alt="dms3" />
                  </div>
                  <div>
                    <div className={styles.title}>ECOMMERCE WEBSITES</div>
                    <div className={styles.text}>
                      <ul>
                        <li>API Integration</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className={styles["dms-card"]}>
                  <div className={styles["img-container"]}>
                    <img src={dms4} alt="dms4" style={{ maxWidth: 320 }} />
                  </div>
                  <div>
                    <div className={styles.title}>
                      TRANSPORT MANAGEMENT SYSTEM & APP
                    </div>
                    <div className={styles.text}>
                      <ul>
                        <li>Pickup and delivery planning</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className={styles["dms-card"]}>
                  <div className={styles["img-container"]}>
                    <img src={dms5} alt="dms5" />
                  </div>
                  <div>
                    <div className={styles.title}>
                      CUSTOMER SERVICE MANAGEMENT DASHBOARD
                    </div>
                    <div className={styles.text}>
                      <ul>
                        <li>Merchant & Partner Service</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className={styles["dms-card"]}>
                  <div className={styles["img-container"]}>
                    <img src={dms6} alt="dms6" />
                  </div>
                  <div>
                    <div className={styles.title}>
                      DELIVERY APP & PARTNER APP
                    </div>
                    <div className={styles.text}>
                      <ul>
                        <li>Pickup & Delivery Fulfillment</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className={styles.card}>
                  <div className={styles["img-container"]}>
                    <img src={dms7} alt="dms7" />
                  </div>
                  <div>
                    <div className={styles.title}>PICK-UP SERVICE</div>
                    <div className={styles.text}>
                      {/* <ul>
                      <li></li>
                    </ul> */}
                    </div>
                  </div>
                </div>
                <div className={styles.card}>
                  <div className={styles["img-container"]}>
                    <img src={dms8} alt="dms8" />
                  </div>
                  <div>
                    <div className={styles.title}>LINEHAUL SERVICE</div>
                    <div className={styles.text}>
                      {/* <ul>
                      <li></li>
                    </ul> */}
                    </div>
                  </div>
                </div>
                <div className={styles.card}>
                  <div className={styles["img-container"]}>
                    <img src={dms9} alt="dms9" />
                  </div>
                  <div>
                    <div className={styles.title}>
                      LAST MILE DELIVERY SERVICE
                    </div>
                    <div className={styles.text}>
                      {/* <ul>
                      <li></li>
                    </ul> */}
                    </div>
                  </div>
                </div>
                <div className={styles.card}>
                  <div className={styles["img-container"]}>
                    <img src={dms10} alt="dms10" />
                  </div>
                  <div>
                    <div className={styles.title}>
                      MOBILE PAYMENT INTEGRATION
                    </div>
                    <div className={styles.text}>
                      {/* <ul>
                      <li></li>
                    </ul> */}
                    </div>
                  </div>
                </div>
                <div className={styles.card}>
                  <div className={styles["img-container"]}>
                    <img src={dms11} alt="dms11" />
                  </div>
                  <div>
                    <div className={styles.title}>CASH ON DELIVERY</div>
                    <div className={styles.text}>
                      {/* <ul>
                      <li></li>
                    </ul> */}
                    </div>
                  </div>
                </div>
              </MultiCarousel>
            </FadeInSection>
          </div>
        </div>

        {/* <div className="banner-container">
        <img
          src="https://placehold.jp/1000x200.png"
          alt="Road"
          className="road-img"
        />
        <img
          src="https://placehold.jp/80x60.png"
          alt="Car"
          className="car-svg"
          style={{ border: "1px solid black" }}
        />
        <img
          src="https://placehold.jp/80x60.png"
          alt="Car"
          className="man-svg"
          style={{ border: "1px solid black" }}
        />
      </div> */}
      </div>
    </>
  );
};

export default OurTechnology;
