import React, { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Brands, Layout, Leadership, SupplierPartners } from "./components";
import { Home, AboutUs, ContactUs, OurTechnology } from "./components";
import "./App.css";
import Loader from "./components/Loader";

const router = createBrowserRouter([
  {
    id: "root",
    path: "/",
    Component: Layout,
    children: [
      {
        index: true,
        Component: Home,
      },
      {
        path: "about_us",
        Component: AboutUs,
      },
      {
        path: "leadership",
        Component: Leadership,
      },
      {
        path: "brands",
        Component: Brands,
      },
      {
        path: "supplier_partners",
        Component: SupplierPartners,
      },
      {
        path: "our_technology",
        Component: OurTechnology,
      },
      {
        path: "contact_us",
        Component: ContactUs,
      },
    ],
  },
]);

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleLoad = () => setLoading(false);
    // Check if the page has already loaded
    if (document.readyState === "complete") {
      handleLoad(); // If page has already loaded, set loading to false immediately
    } else {
      window.addEventListener("load", handleLoad);
    }

    return () => window.removeEventListener("load", handleLoad);
  }, []);
  return <>{loading ? <Loader /> : <RouterProvider router={router} />}</>;
}

export default App;
