import React, { useState } from "react";
import { Accordion, Carousel, Col, Row } from "react-bootstrap";
import styles from "./Home.module.css";
import MultiCarousel from "react-multi-carousel"; // Renamed import
import "react-multi-carousel/lib/styles.css";
import images from "../../assets/images/partners";
import Banner1 from "../../assets/images/Banner1.jpg";
import Banner2 from "../../assets/images/Banner2.jpg";
import Banner3 from "../../assets/images/Banner3.jpg";
import FadeInSection from "../FadeInSection";
import wsl from "../../assets/images/wsl.svg";

const responsive = {
  extraLargeDesktop: {
    breakpoint: { max: 4000, min: 1600 },
    items: 6,
  },
  superLargeDesktop: {
    breakpoint: { max: 1600, min: 1200 },
    items: 6,
  },
  largeDesktop: {
    breakpoint: { max: 1200, min: 1024 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 1024, min: 768 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

const Home = () => {
  const [activeKey, setActiveKey] = useState("0");

  const handleAccordionClick = (key) => {
    setActiveKey((prevKey) => (prevKey === key ? prevKey : key));
  };
  return (
    <>
      <div className={styles["carousel-container"]}>
        <Carousel className={styles.carousel} data-bs-theme="dark">
          <Carousel.Item className={styles["carousel-item"]}>
            <img src={Banner1} alt="slide"></img>
          </Carousel.Item>
          <Carousel.Item className={styles["carousel-item"]}>
            <img src={Banner2} alt="slide"></img>
          </Carousel.Item>
          <Carousel.Item className={styles["carousel-item"]}>
            <img src={Banner3} alt="slide"></img>
          </Carousel.Item>
        </Carousel>
      </div>
      <div className="content-wrapper">
        <div className={styles["value-proposition"]}>
          <FadeInSection>
            <div className={styles["content-title"]}>Our Value Proposition</div>
            <p>Integrated Commerce Solutions for Southeast Asian Growth</p>
          </FadeInSection>

          <div className={styles.content}>
            <Row>
              <Col xs={12} md={4} sm={6} className="px-4">
                <FadeInSection delay="0.2s">
                  <div className={styles.card}>
                    <div className={styles.image1}></div>
                    <div>
                      <div className={styles.title}>
                        Comprehensive <br />
                        Digital-to-Physical Platform:
                      </div>
                      <div className={styles.text}>
                        Seamlessly connecting SMEs to regional markets through
                        an integrated digital distribution platform, efficient
                        logistics, and omnichannel retail presence.
                      </div>
                    </div>
                  </div>
                </FadeInSection>
              </Col>
              <Col xs={12} md={4} sm={6} className="px-4">
                <FadeInSection delay="0.3s">
                  <div className={styles.card}>
                    <div className={styles.image2}></div>
                    <div>
                      <div className={styles.title}>
                        Cross-Border Expansion Made Easy:
                      </div>
                      <div className={styles.text}>
                        Enabling businesses to reach customers across Myanmar,
                        Singapore, and Thailand, with planned expansion to more
                        Southeast Asian countries and Japan.
                      </div>
                    </div>
                  </div>
                </FadeInSection>
              </Col>
              <Col xs={12} md={4} sm={6} className="px-4">
                <FadeInSection delay="0.4s">
                  <div className={styles.card}>
                    <div className={styles.image3}></div>
                    <div>
                      <div className={styles.title}>
                        One-Stop Solution for Regional Trade:
                      </div>
                      <div className={styles.text}>
                        Offering end-to-end services from product listing to
                        door-to-door delivery, simplifying cross-border commerce
                        for businesses and consumers alike.
                      </div>
                    </div>
                  </div>
                </FadeInSection>
              </Col>
            </Row>
          </div>
        </div>
        <div className={styles["why-sentosa-link"]}>
          <FadeInSection>
            <div className={styles["content-title"]}>Why Sentosa Link</div>
          </FadeInSection>
          <Row>
            <Col xs={12} md={6} sm={12}>
              <div className={styles["why-sentosa-link-content"]}>
                <Accordion activeKey={activeKey}>
                  <FadeInSection delay="0.2s">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header
                        onClick={() => handleAccordionClick("0")}
                      >
                        Unparalleled Regional Reach:
                      </Accordion.Header>
                      <Accordion.Body>
                        Operational in Myanmar, Singapore, and Thailand, with
                        planned expansion to Vietnam, Laos, Malaysia, Cambodia,
                        and Japan.
                      </Accordion.Body>
                    </Accordion.Item>
                  </FadeInSection>
                  <FadeInSection delay="0.3s">
                    <Accordion.Item eventKey="1">
                      <Accordion.Header
                        onClick={() => handleAccordionClick("1")}
                      >
                        Comprehensive Omnichannel Presence:
                      </Accordion.Header>
                      <Accordion.Body>
                        Seamlessly connecting digital distribution platforms,
                        online stores, and physical mini marts for maximum
                        market penetration.
                      </Accordion.Body>
                    </Accordion.Item>
                  </FadeInSection>
                  <FadeInSection delay="0.4s">
                    <Accordion.Item eventKey="2">
                      <Accordion.Header
                        onClick={() => handleAccordionClick("2")}
                      >
                        End-to-End Logistics Solutions:
                      </Accordion.Header>
                      <Accordion.Body>
                        Offering efficient sea and air cargo operations between
                        countries, with door-to-door delivery service in major
                        Myanmar cities.
                      </Accordion.Body>
                    </Accordion.Item>
                  </FadeInSection>
                </Accordion>
              </div>
            </Col>
            <Col
              xs={{ span: 12, offset: 0 }}
              md={{ span: 5, offset: 1 }}
              sm={{ span: 12, offset: 0 }}
            >
              <FadeInSection delay="0.5s">
                <div className={styles["why-sentosa-link-image"]}>
                  <img src={wsl} alt="placeholder-img"></img>
                </div>
              </FadeInSection>
            </Col>
          </Row>
        </div>
        <div className={styles["partners-in-collaboration"]}>
          <FadeInSection>
            <div
              className={styles["content-title"]}
              style={{ marginBottom: "3.5rem" }}
            >
              Partners in Collaboration
            </div>
          </FadeInSection>

          <div style={{ width: "100%" }}>
            <FadeInSection delay="0.2s">
              <MultiCarousel
                responsive={responsive}
                swipeable={true}
                draggable={true}
                showDots={true}
                arrows={false}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={3000}
                keyBoardControl={true}
                containerClass="carousel-container"
                // partialVisible={true}
                customTransition="transform 500ms ease-in-out"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                //centerMode={true}
                rewind={true}
                slidesToSlide={2}
                rewindWithAnimation={true}
                // deviceType={this.props.deviceType}
                dotListClass="custom-dot-list-style"
                focusOnSelect={true}
                // itemClass="carousel-item-padding-40-px"
              >
                <div className={styles["partner-logo"]}>
                  <img alt="partner-logo" src={images.image1} />
                </div>
                <div className={styles["partner-logo"]}>
                  <img alt="partner-logo" src={images.image2} />
                </div>
                <div className={styles["partner-logo"]}>
                  <img alt="partner-logo" src={images.image3} />
                </div>
                <div className={styles["partner-logo"]}>
                  <img alt="partner-logo" src={images.image4} />
                </div>
                <div className={styles["partner-logo"]}>
                  <img alt="partner-logo" src={images.image5} />
                </div>
                <div className={styles["partner-logo"]}>
                  <img alt="partner-logo" src={images.image6} />
                </div>
                <div className={styles["partner-logo"]}>
                  <img alt="partner-logo" src={images.image7} />
                </div>
                <div className={styles["partner-logo"]}>
                  <img alt="partner-logo" src={images.image8} />
                </div>
              </MultiCarousel>
            </FadeInSection>
          </div>
        </div>
        <div className={styles["why-choose-us"]}>
          <FadeInSection>
            <div className={styles["content-title"]}>
              Why choose us over others?
            </div>
          </FadeInSection>
          <FadeInSection delay="0.2s">
            <div
              className={styles["content-text"]}
              style={{ maxWidth: "55rem" }}
            >
              "Sentosa Link offers an integrated approach to digital
              distribution, logistics, and retail in Southeast Asia.
              <br /> We combine a digital platform, cargo services, and
              omnichannel retail presence. With regional expertise and expansion
              plans, we empower SMEs, facilitate cross-border trade, and provide
              efficient logistics. Our innovative, comprehensive solutions adapt
              to evolving business and consumer needs."
            </div>
          </FadeInSection>
        </div>
      </div>
    </>
  );
};

export default Home;
