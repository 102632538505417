import React, { useMemo } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  useLoadScript,
} from "@react-google-maps/api";
import styles from "./ContactUs.module.css";

const mapContainerStyle = {
  width: "100%",
  height: "256px",
  borderRadius: "20px",
  marginBottom: "3.12rem",
};

const CustomMap = React.memo(() => {
  console.log("rendered");
  // const { isLoaded, loadError } = useLoadScript({
  //   id: "__googleMapsScriptId",
  //   googleMapsApiKey: `AIzaSyDr9E_WcGEP5kEb4Hpt_cSj4QOU4D14RYc`,
  //   version: "weekly",
  // });

  const center = useMemo(
    () => ({
      lat: 1.2922871,
      lng: 103.8508504,
    }),
    []
  );

  const mapOptions = useMemo(
    () => ({
      disableDefaultUI: true,
      zoomControl: false,
      streetViewControl: false,
      fullscreenControl: false,
    }),
    []
  );

  // if (loadError) {
  //   return <div>Error loading maps</div>;
  // }

  // if (!isLoaded) {
  //   return <div>Loading maps</div>;
  // }

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={17}
      center={center}
      options={mapOptions}
    >
      <MarkerF position={center} />
    </GoogleMap>
  );
});

export default CustomMap;
