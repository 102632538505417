import React, { useState } from "react";
import styles from "./ContactUs.module.css";
import { Button, Form, Row, Col, Alert } from "react-bootstrap";
import CustomMap from "./CustomMap";
import { ReactComponent as MapPinOutlined } from "../../assets/icons/MapPinOutlined.svg";
import { ReactComponent as PhoneFilled } from "../../assets/icons/PhoneFilled.svg";
import { ReactComponent as EmailFilled } from "../../assets/icons/EmailFilled.svg";
import { ReactComponent as Facebook } from "../../assets/icons/Facebook.svg";
import { ReactComponent as Messenger } from "../../assets/icons/Messenger.svg";
import { ReactComponent as Whatsapp } from "../../assets/icons/Whatsapp.svg";
import { ReactComponent as TikTok } from "../../assets/icons/TikTok.svg";
import FadeInSection from "../FadeInSection";
import SubBanner from "../SubBanner";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(null);
  const [submissionError, setSubmissionError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: false,
    });
    setSubmissionError(null); // Clear error on input change
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = "Name is required";
    if (!formData.email.trim()) newErrors.email = "Email is required";
    if (!formData.message.trim()) newErrors.message = "Message is required";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setSubmitSuccess(false);
      return;
    }

    const formPayload = {
      access_key: "ef2a9648-bb82-48be-abec-ef38ac8b0e4d",
      name: formData.name,
      email: formData.email,
      message: formData.message,
    };

    try {
      setLoading(true);
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formPayload),
      });

      if (response.ok) {
        setSubmitSuccess(true);
        setSubmitted(true);
        setFormData({
          name: "",
          email: "",
          message: "",
        });
        setLoading(false);
      } else {
        setSubmitSuccess(false);
        setLoading(false);
        setSubmissionError("Failed to submit the form. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setSubmitSuccess(false);
      setLoading(false);
      setSubmissionError("An unexpected error occurred. Please try again.");
    }
  };

  return (
    <>
      <SubBanner />
      <div className="content-wrapper">
        <div className={styles["contact-info"]}>
          <Row className="justify-content-between">
            <Col xs={12} md={5}>
              <FadeInSection>
                <div className={styles["contact-form-title"]}>
                  Love to hear from you,
                  <br /> Get in touch 👋
                </div>
              </FadeInSection>

              {submitted && (
                <FadeInSection>
                  <Alert
                    variant={submitSuccess ? "success" : "danger"}
                    onClose={() => setSubmitted(false)}
                    dismissible
                  >
                    {submitSuccess
                      ? "Thank you for reaching out. We will contact you shortly."
                      : "Something went wrong. Please try again."}
                  </Alert>
                </FadeInSection>
              )}

              {submissionError && (
                <FadeInSection>
                  <Alert
                    variant="danger"
                    onClose={() => setSubmissionError(null)}
                    dismissible
                  >
                    {submissionError}
                  </Alert>
                </FadeInSection>
              )}

              <div className={styles["contact-form"]}>
                <FadeInSection delay="0.2s">
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12} md={6} sm={6}>
                        <Form.Group className="mb-4" controlId="name">
                          <Form.Label>Your Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            isInvalid={errors.name}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6} sm={6}>
                        <Form.Group className="mb-4" controlId="email">
                          <Form.Label>Your Email</Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            isInvalid={errors.email}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Form.Group className="mb-4" controlId="message">
                      <Form.Label>Your Message</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        isInvalid={errors.message}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.message}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <FadeInSection delay="0.35s">
                      <Button
                        variant="custom"
                        type="submit"
                        className="custom-btn"
                      >
                        {loading ? "Sending..." : "Send Message"}
                      </Button>
                    </FadeInSection>
                  </Form>
                </FadeInSection>
              </div>
            </Col>

            <Col xs={12} md={5}>
              <FadeInSection delay="0.5s">
                <CustomMap />
              </FadeInSection>
              <FadeInSection delay="0.6s">
                <div className={styles["contact-details"]}>
                  <div>
                    <span
                      style={{
                        width: "1.5rem",
                        height: "1.5rem",
                        marginRight: "0.94rem",
                      }}
                    >
                      <MapPinOutlined />
                    </span>
                    <span>
                      111, North Bridge Road, 02-02B Peninsula Plaza, 179098
                    </span>
                  </div>
                  <div>
                    <span
                      style={{
                        width: "1.5rem",
                        height: "1.5rem",
                        marginRight: "0.94rem",
                      }}
                    >
                      <PhoneFilled />
                    </span>
                    <span>+65 9881 2787</span>
                  </div>
                  <div>
                    <span
                      style={{
                        width: "1.5rem",
                        height: "1.5rem",
                        marginRight: "0.94rem",
                      }}
                    >
                      <EmailFilled />
                    </span>
                    <span>hello@sentosalink.com</span>
                  </div>
                </div>
              </FadeInSection>

              <FadeInSection delay="0.7s">
                <div className={styles["contact-socials"]}>
                  <a href="https://www.facebook.com/share/nfUDuVo11cYyJZdx/?mibextid=LQQJ4d">
                    <div className={styles["social-icons"]}>
                      <Facebook />
                    </div>
                  </a>
                  <a href="http://m.me/sentosalink">
                    <div className={styles["social-icons"]}>
                      <Messenger />
                    </div>
                  </a>
                  <a href="https://wa.me/6598812787">
                    <div className={styles["social-icons"]}>
                      <Whatsapp />
                    </div>
                  </a>
                  <a href="#">
                    <div className={styles["social-icons"]}>
                      <TikTok />
                    </div>
                  </a>
                </div>
              </FadeInSection>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
