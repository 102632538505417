import React, { useState } from "react";
import styles from "./AboutUs.module.css";
import { Col, Modal, Row } from "react-bootstrap";
import FadeInSection from "../FadeInSection";
import Tabs from "./Tabs";
import bs4 from "../../assets/images/bs4.svg";
import hww from "../../assets/images/hww.svg";
import SubBanner from "../SubBanner";
import KyawThetPaing from "../../assets/images/KyawThetPaing.svg";
import KyawMinAung from "../../assets/images/KyawMinAung.svg";
import BoBoWin from "../../assets/images/BoBoWin.svg";

const leadershipData = [
  {
    id: 1,
    name: "Kyaw Min Aung",
    position: "Director",
    description:
      "Lorem ipsum dolor sit amet consectetur. Elementum ac habitant eget sed lobortis feugiat leo consectetur adipiscing. In eget arcu fringilla varius nulla sodales. Vel libero tortor nunc lorem pharetra lorem egestas. Arcu gravida ultrices vitae adipiscing feugiat suspendisse sed nisl viverra. Viverra ut dui nisi volutpat sit facilisis cursus volutpat. Nisl nulla phasellus nullam sodales. Lacus vitae porttitor vivamus sit feugiat lacus quis integer. Eu duis nunc libero id orci mauris eu. Ut adipiscing nisi quis in. Consectetur adipiscing donec id neque interdum ipsum suspendisse. Accumsan nullam mollis ullamcorper facilisi.",
    image: KyawMinAung,
  },
  {
    id: 2,
    name: "Kyaw Thet Paing",
    position: "Director, Co-Founder",
    description:
      "Lorem ipsum dolor sit amet consectetur. Elementum ac habitant eget sed lobortis feugiat leo consectetur adipiscing. In eget arcu fringilla varius nulla sodales. Vel libero tortor nunc lorem pharetra lorem egestas. Arcu gravida ultrices vitae adipiscing feugiat suspendisse sed nisl viverra. Viverra ut dui nisi volutpat sit facilisis cursus volutpat. Nisl nulla phasellus nullam sodales. Lacus vitae porttitor vivamus sit feugiat lacus quis integer. Eu duis nunc libero id orci mauris eu. Ut adipiscing nisi quis in. Consectetur adipiscing donec id neque interdum ipsum suspendisse. Accumsan nullam mollis ullamcorper facilisi.",
    image: KyawThetPaing,
  },
  {
    id: 3,
    name: "Bo Bo Win",
    position: "Independent Director, Founder at YANANT THIT PTE. LTD.",
    description:
      "Lorem ipsum dolor sit amet consectetur. Elementum ac habitant eget sed lobortis feugiat leo consectetur adipiscing. In eget arcu fringilla varius nulla sodales. Vel libero tortor nunc lorem pharetra lorem egestas. Arcu gravida ultrices vitae adipiscing feugiat suspendisse sed nisl viverra. Viverra ut dui nisi volutpat sit facilisis cursus volutpat. Nisl nulla phasellus nullam sodales. Lacus vitae porttitor vivamus sit feugiat lacus quis integer. Eu duis nunc libero id orci mauris eu. Ut adipiscing nisi quis in. Consectetur adipiscing donec id neque interdum ipsum suspendisse. Accumsan nullam mollis ullamcorper facilisi.",
    image: BoBoWin,
  },
];

const AboutUs = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [selectedLeader, setSelectedLeader] = useState(null);
  const handleReadMore = (e) => {
    e.preventDefault();
    setIsExpanded(true);
  };
  const handleImageClick = (leaderId) => {
    const leader = leadershipData.find((l) => l.id === leaderId);
    setSelectedLeader(leader);
    setModalShow(true);
  };
  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className={styles["modal-body"]}>
            <div className="container">
              <Row>
                <Col xs={12} md={4} sm={12}>
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={selectedLeader?.image}
                    className="img-fluid"
                    alt={selectedLeader?.name}
                  />
                </Col>
                <Col xs={12} md={{ span: 7, offset: 1 }} sm={12}>
                  <h5>{selectedLeader?.name}</h5>
                  <h6>{selectedLeader?.position}</h6>
                  <p style={{ marginTop: "2rem" }}>
                    {selectedLeader?.description}
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <SubBanner />
      <div className="content-wrapper">
        <div className={styles["about-us-container"]}>
          {/* <div className={styles["carousel-container"]}>
          <Carousel className={styles.carousel} data-bs-theme="dark">
            <Carousel.Item className={styles["carousel-item"]}>
              <img src={Banner1} alt="slide"></img>
            </Carousel.Item>
            <Carousel.Item className={styles["carousel-item"]}>
              <img src={Banner2} alt="slide"></img>
            </Carousel.Item>
            <Carousel.Item className={styles["carousel-item"]}>
              <img src={Banner3} alt="slide"></img>
            </Carousel.Item>
          </Carousel>
        </div> */}
          <div className={styles["brand-story"]}>
            <FadeInSection>
              <div className={styles["brand-story-title"]}>Our Brand Story</div>{" "}
            </FadeInSection>
            <FadeInSection delay="0.1s">
              <p>
                SENTOSA LINK PTE.LTD. was born from a vision to revolutionize
                Southeast Asian commerce. <br />
                We recognized the challenges faced by SMEs in reaching broader
                markets and the need for efficient cross-border logistics. Our
                solution? An integrated ecosystem:
              </p>
            </FadeInSection>
            <div className={styles["brand-story-content"]}>
              <Row>
                <Col xs={12} md={4} sm={6}>
                  <FadeInSection delay="0.2s">
                    <div className={styles.card}>
                      <div className={styles.image1}></div>
                      <div className={styles.text}>
                        A digital distribution platform connecting SMEs to
                        regional markets
                      </div>
                    </div>
                  </FadeInSection>
                </Col>
                <Col xs={12} md={4} sm={6}>
                  <FadeInSection delay="0.3s">
                    <div className={styles.card}>
                      <div className={styles.image2}></div>
                      <div className={styles.text}>
                        Efficient cargo services ensuring seamless product
                        movement
                      </div>
                    </div>
                  </FadeInSection>
                </Col>
                <Col xs={12} md={4} sm={6}>
                  <FadeInSection delay="0.4s">
                    <div className={styles.card}>
                      <div className={styles.image3}></div>
                      <div className={styles.text}>
                        Physical mini marts and online stores bridging digital
                        and traditional retail
                      </div>
                    </div>
                  </FadeInSection>
                </Col>
              </Row>
              <Row style={{ marginTop: "5rem" }}>
                <Col xs={12} md={6} sm={12}>
                  <FadeInSection delay="0.5s">
                    <img
                      alt="brand-story"
                      src={bs4}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </FadeInSection>
                </Col>
                <Col xs={12} md={{ span: 5, offset: 1 }} sm={12}>
                  <FadeInSection delay="0.6s">
                    <div className={styles.story}>
                      Starting in Myanmar, Singapore, and Thailand, we've
                      empowered local businesses to expand regionally. Our
                      door-to-door delivery in major Myanmar cities has set new
                      standards in customer convenience.
                      <br />
                      <br /> Today, we're more than a company – we're a link
                      between dreams and opportunities.{"  "}
                      {!isExpanded && (
                        <>
                          <a href="/" onClick={handleReadMore}>
                            Read More
                          </a>
                        </>
                      )}
                      {isExpanded && (
                        <>
                          {" "}
                          As we expand to more Southeast Asian countries and
                          Japan, our mission remains clear: to connect
                          businesses with consumers, blend online with offline
                          retail, and foster regional economic growth.
                          <br />
                          <br />
                          At SENTOSA LINK, we're not just delivering products;
                          we're delivering possibilities across Southeast Asia.
                        </>
                      )}
                    </div>
                  </FadeInSection>
                </Col>
              </Row>
            </div>
          </div>
          <div className={styles["mission-vision"]}>
            <FadeInSection>
              <Tabs />
            </FadeInSection>
          </div>
          <div className={styles["leadership"]}>
            <FadeInSection>
              <div className={styles["leadership-title"]}>Our Leadership</div>
            </FadeInSection>
            <FadeInSection delay="0.1s">
              <div className={styles["leadership-description"]}>
                With operations across Asia, our organization runs smoothly
                thanks to the inspiring leaders our employees look up to. These
                pioneers are leading Sentosa Link into a bright new era.
              </div>
            </FadeInSection>

            <div className={styles["leadership-content"]}>
              <Row>
                <Col xs={12} md={4} sm={6}>
                  <FadeInSection delay="0.2s">
                    <div className={styles.card}>
                      <div
                        className={styles.image}
                        onClick={() => handleImageClick(1)}
                      >
                        <img src={KyawMinAung} alt="KyawMinAung"></img>
                      </div>
                      <div>
                        <div className={styles.name}>Kyaw Min Aung</div>
                        <div className={styles.detail}>Director</div>
                      </div>
                    </div>
                  </FadeInSection>
                </Col>
                <Col xs={12} md={4} sm={6}>
                  <FadeInSection delay="0.3s">
                    <div className={styles.card}>
                      <div
                        className={styles.image}
                        onClick={() => handleImageClick(2)}
                      >
                        {" "}
                        <img src={KyawThetPaing} alt="KyawThetPaing"></img>
                      </div>
                      <div>
                        <div className={styles.name}>Kyaw Thet Paing</div>
                        <div className={styles.detail}>
                          Director
                          <br /> Co-Founder
                        </div>
                      </div>
                    </div>
                  </FadeInSection>
                </Col>
                <Col xs={12} md={4} sm={6}>
                  <FadeInSection delay="0.4s">
                    <div className={styles.card}>
                      <div
                        className={styles.image}
                        onClick={() => handleImageClick(3)}
                      >
                        <img src={BoBoWin} alt="BoBoWin"></img>
                      </div>
                      <div>
                        <div className={styles.name}>Bo Bo Win</div>
                        <div className={styles.detail}>
                          Independent Director <br />
                          Founder at YANANT THIT PTE. LTD.
                        </div>
                      </div>
                    </div>
                  </FadeInSection>
                </Col>
              </Row>
            </div>
          </div>

          <div className={styles["how-we-work"]}>
            <FadeInSection>
              <div className={styles["how-we-work-title"]}>How We Work</div>
            </FadeInSection>
            <div className={styles["how-we-work-content"]}>
              <Row>
                <FadeInSection delay="0.1s">
                  <div className={styles["how-we-work-text"]}>
                    <p>
                      {/* <span className={styles["lorem-ipsum"]}>
                        Lorem ipsum dolor sit amet consectetur. Arcu laoreet
                        felis pharetra tortor duis. Nulla mattis tellus
                        pellentesque consectetur magna in et vulputate egestas.
                        Tortor ut erat viverra pretium tristique non molestie
                        neque.
                      </span> */}
                    </p>
                  </div>
                </FadeInSection>
                <FadeInSection delay="0.2s">
                  <div className={styles["how-we-work-image"]}>
                    <img src={hww} alt="How We Work"></img>
                  </div>
                </FadeInSection>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
